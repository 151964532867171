import {
  APP_MANAGED_ROUTES,
  FooterLinkProps,
  highmarkCommon,
  useGetAppInitDetails,
  useIsAuthenticated,
} from '@highmark-web/highmark-common';
import { useIntl } from '@leagueplatform/locales';

export const useFooterLinks = () => {
  const { formatMessage } = useIntl();
  const { isAuthenticated } = useIsAuthenticated();
  const { eligibilityInfo } = useGetAppInitDetails({
    enabled: !!isAuthenticated,
  });

  const legalUrl = APP_MANAGED_ROUTES.LEGAL;
  const { qualtricsSurveyUrl } = highmarkCommon.getConfig();

  const isPostAuth = isAuthenticated && eligibilityInfo?.eligible;

  // TODO update URLs to valid destinations, including non postAuth legal link: HMRK-5763
  const footerLinks: FooterLinkProps[] = isPostAuth
    ? [
        {
          text: formatMessage({ id: 'LEGAL' }),
          url: legalUrl,
          linkProps: {
            target: '_self',
          },
        },
        {
          text: formatMessage({ id: 'ALL_LANGUAGES' }),
          url: APP_MANAGED_ROUTES.TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES,
          linkProps: {
            target: '_self',
          },
        },
        {
          text: formatMessage({ id: 'GIVE_FEEDBACK' }),
          url: qualtricsSurveyUrl,
          linkProps: {
            target: '_blank',
          },
        },
      ]
    : [];

  return { footerLinks };
};
