import React from 'react';
import { PrivateBasePage } from 'components/private-base-page.component';
import {
  APP_MANAGED_ROUTES,
  ERROR_TYPES,
  ErrorState,
  INELIGIBLE_CODES,
  LegalPage,
  LoadingSpinner,
  MfaEnrollment,
  PageEligibilityContext,
  RegionSelectionContext,
  useAnalyticsIdentify,
  usePrivateRoutesContainer,
} from '@highmark-web/highmark-common';
import {
  ACHIEVEMENTS_ENABLED,
  REWARDS_EVENTS_ENABLED,
} from '@leagueplatform/rewards-api';
import { RewardsEventController } from '@leagueplatform/rewards-events';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { useConfigProperty } from '@leagueplatform/core';
import { Route, Switch } from '@leagueplatform/routing';
import { WalkthroughContainer } from './app-walkthrough/walkthrough-container.component';

export const PrivateRoutesContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    contentConfig,
    regionSelection,
    eligibilityInfo,
    enableLiveChat,
    showWalkthrough,
    onCompleteWalkthrough,
    showMfa,
    onCompleteMfa,
    isLoading,
    isErrorAppInitDetails,
    isErrorUserProfile,
  } = usePrivateRoutesContainer();

  const { data: isRewardsLayerEnabled } = useFeatureFlagQuery(
    REWARDS_EVENTS_ENABLED,
  );

  const { data: isAchievementsEnabled } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);

  // Send user data to analytics SDK
  const tenantId = useConfigProperty('core.tenantId');
  useAnalyticsIdentify(tenantId!);

  if (isLoading) return <LoadingSpinner />;

  if (isErrorAppInitDetails) {
    return <ErrorState isFullPage errorType={ERROR_TYPES.API_ERROR} />;
  }

  // Show not eligible screen if user isn't eligible to use the app
  if (!eligibilityInfo.eligible) {
    // Show specific not eligible screen if user hasn't had coverage for over 365 days
    if (eligibilityInfo.reasonCode === INELIGIBLE_CODES.OVER_365_DAYS) {
      return <ErrorState isFullPage errorType={ERROR_TYPES.OVER_365_DAYS} />;
    }
    // Otherwise, show generic not eligible screen
    return <ErrorState isFullPage errorType={ERROR_TYPES.NOT_ELIGIBLE} />;
  }

  // Show error screen if user is eligible, but userProfile API fails
  if (isErrorUserProfile)
    return <ErrorState isFullPage errorType={ERROR_TYPES.API_ERROR} />;

  // Show walkthrough slides if needed
  if (showWalkthrough) {
    return (
      <Switch>
        <Route path={APP_MANAGED_ROUTES.LEGAL} component={LegalPage} />
        <Route>
          <WalkthroughContainer onCompleteWalkthrough={onCompleteWalkthrough} />
        </Route>
      </Switch>
    );
  }

  if (showMfa) {
    return <MfaEnrollment onEnrollSuccess={onCompleteMfa} />;
  }

  return (
    <RegionSelectionContext.Provider value={regionSelection}>
      <PrivateBasePage enableLiveChat={enableLiveChat}>
        {isRewardsLayerEnabled && isAchievementsEnabled && (
          <RewardsEventController />
        )}
        <PageEligibilityContext.Provider value={contentConfig}>
          {children}
        </PageEligibilityContext.Provider>
      </PrivateBasePage>
    </RegionSelectionContext.Provider>
  );
};
